import {createGlobalStyle} from 'styled-components';
import {device} from './device';
const GlobalStyle = createGlobalStyle`;
    body{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Barlow', sans-serif;
        font-weight: 700;
    }
    p{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        color:#666666;
        margin-bottom:20px;
        font-size:15px;
        line-height:25px;
    }
    a{
        font-size:16px;
        font-family: 'Poppins', sans-serif;
        font-weight:500;
    }
    img{
        margin-bottom:0px;
    }

    h1{
        font-size:50px;
        line-height:55px;
        margin-bottom:20px;
        text-transform:uppercase;
        font-weight:600;

        @media ${device.laptop} {
            font-size:45px;
            line-height:50px;
        }

        @media ${device.tablet} {
            text-align:center;
        }
        @media ${device.mobileXL} {
            font-size:40px;
            line-height:45px;
        }
    }

    h2{
        font-size: 42px;
        line-height:48px;
        margin-bottom: 30px;
        font-weight:700;
        color:#333;

        @media ${device.tablet} {
            font-size:40px;
        }
    }
    h3{
        font-size: 36px;
        line-height:42px;
        margin-bottom: 30px;
        font-weight:600;
        text-transform:uppercase;
        font-weight:700;
    }
    h4{
        font-size:30px;
        color:#333;
        line-height:35px;
        text-align:center;
        margin-bottom:20px;
        font-weight:700;

        @media ${device.tablet} {
            font-size:28px;
            line-height:32px;
        }
    }
    h5{
        font-size:24px;
        color:#333;
        line-height:29px;
        text-align:center;
        margin-bottom:20px;
        font-weight:700;
    }

    h6{
        font-size:18px;
        line-height:24px;
        margin-bottom:20px;
        font-weight:600;

        @media ${device.tablet} {
            text-align:center;
        }

        @media ${device.mobileXL} {
            font-size:17px;
            line-height:23px;
        }
    }
`;

export default GlobalStyle;