import styled from 'styled-components';
import {device} from './device';

export const Commonbtn = styled.a`
    background: #ef5350;
    color: #fff;
    padding: 8px 40px;
    text-decoration:none;
    border-radius: 5px;
    display:inline-block;
    text-transform:uppercase;
    cursor:pointer;

    :hover{
        background:#e91916;
    }

    @media ${device.mobileXL} {
        padding: 8px 30px;
    }
`;

export const SectionHeading = styled.h2`
    text-align:center;
    line-height:1;
    text-transform:uppercase;
`;
